import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1071.000000 944.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,944.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M1845 8059 c-16 -5 -77 -14 -135 -19 -116 -10 -199 -26 -247 -46 -17
-8 -39 -14 -49 -14 -24 0 -138 -40 -174 -61 -8 -5 -45 -24 -83 -43 -37 -19
-77 -42 -89 -51 -13 -8 -32 -15 -44 -15 -31 0 -84 -32 -84 -50 0 -8 -51 -67
-113 -131 -123 -126 -147 -161 -147 -209 0 -28 -11 -57 -41 -110 -5 -8 -11
-19 -13 -25 -17 -51 -29 -95 -37 -138 -4 -29 -13 -74 -18 -102 -13 -67 -13
-284 0 -365 5 -36 14 -93 18 -128 4 -34 12 -67 19 -74 7 -7 12 -28 12 -47 1
-20 7 -47 15 -61 8 -14 15 -38 15 -55 0 -16 7 -41 15 -55 8 -14 14 -48 15 -76
0 -27 4 -54 9 -60 5 -5 19 -36 30 -68 11 -32 27 -66 36 -76 8 -9 15 -20 15
-24 0 -23 110 -244 133 -268 22 -23 37 -61 37 -94 0 -27 51 -117 89 -156 15
-14 33 -39 41 -55 8 -15 26 -44 40 -63 14 -19 31 -46 38 -61 8 -14 26 -39 43
-57 16 -17 29 -38 29 -47 0 -17 36 -77 69 -114 11 -13 21 -28 21 -33 0 -5 30
-39 67 -76 69 -70 103 -116 103 -142 0 -19 109 -150 124 -150 17 0 112 -112
129 -151 17 -42 67 -99 85 -99 19 0 202 -191 202 -211 0 -18 30 -49 48 -49 15
0 68 -46 94 -83 18 -24 24 -51 29 -122 8 -112 19 -155 68 -267 12 -26 21 -51
21 -56 0 -47 194 -272 234 -272 8 0 16 -7 20 -15 3 -8 10 -15 16 -15 6 0 26
-11 43 -23 37 -27 151 -87 166 -87 6 0 27 -11 47 -25 20 -13 52 -27 73 -30 20
-4 45 -11 56 -16 11 -5 43 -14 70 -19 28 -5 68 -13 90 -19 22 -6 84 -11 137
-11 53 0 98 -4 100 -8 2 -5 50 -43 108 -85 58 -42 124 -94 146 -116 27 -27 51
-41 67 -41 26 0 148 -76 179 -111 15 -17 115 -89 124 -89 2 0 20 -12 41 -26
21 -14 72 -42 113 -61 41 -20 77 -40 78 -45 2 -4 10 -8 17 -8 8 0 20 -6 27
-13 7 -7 24 -18 38 -24 56 -28 107 -59 130 -82 14 -12 34 -27 45 -32 11 -5 36
-19 55 -30 19 -12 46 -21 60 -21 31 1 80 -17 80 -29 0 -5 28 -23 63 -39 34
-17 69 -35 77 -40 8 -5 36 -18 61 -30 26 -11 54 -29 64 -40 10 -11 22 -20 28
-20 10 0 68 -24 109 -46 14 -8 44 -14 67 -14 28 0 53 -9 84 -30 25 -16 54 -30
66 -30 12 0 21 -4 21 -9 0 -5 14 -13 32 -16 18 -4 39 -11 48 -15 21 -11 137
-64 183 -84 21 -9 45 -16 53 -16 8 0 42 -14 76 -30 46 -23 74 -30 119 -30 33
0 67 -4 77 -9 31 -18 200 -71 224 -71 12 -1 34 -7 48 -15 14 -8 37 -14 51 -15
15 0 40 -7 55 -15 16 -8 45 -15 64 -15 19 0 53 -7 75 -15 22 -8 56 -14 75 -15
20 0 41 -5 46 -10 6 -6 54 -14 107 -19 53 -5 101 -12 106 -16 6 -3 50 -8 98
-10 48 -2 142 -7 208 -10 170 -9 541 28 570 56 5 5 19 9 30 9 35 0 157 41 197
67 21 12 42 23 46 23 11 0 123 59 137 71 5 5 19 9 32 9 12 0 26 6 30 13 4 8
16 18 26 23 32 18 168 149 189 182 11 19 31 38 44 42 18 7 24 17 24 40 0 17
13 56 30 87 16 31 30 61 30 68 0 6 13 36 30 66 17 31 30 69 30 90 0 20 7 50
16 67 12 26 15 61 12 181 -2 82 -6 153 -11 157 -20 21 -29 -21 -28 -138 1 -96
-2 -128 -13 -143 -9 -11 -16 -40 -16 -65 0 -25 -7 -54 -15 -64 -8 -11 -15 -31
-15 -44 0 -13 -11 -38 -25 -56 -17 -23 -25 -46 -25 -75 0 -28 -5 -44 -15 -47
-8 -4 -15 -11 -15 -17 0 -17 -64 -96 -79 -97 -8 0 -59 -44 -115 -99 -110 -107
-194 -161 -251 -161 -21 0 -57 -12 -88 -30 -30 -16 -65 -30 -79 -30 -13 0 -28
-3 -32 -7 -7 -8 -49 -18 -196 -50 -99 -21 -656 -30 -704 -12 -17 7 -65 16
-106 20 -41 4 -106 15 -145 24 -38 9 -97 19 -130 23 -33 5 -70 13 -82 20 -12
7 -32 12 -45 12 -13 0 -32 7 -42 15 -14 10 -45 15 -102 15 -67 0 -93 5 -150
29 -38 16 -96 37 -129 46 -79 23 -146 49 -207 81 -14 8 -35 14 -46 14 -11 0
-25 7 -32 15 -7 8 -22 15 -34 15 -11 0 -41 14 -66 30 -35 23 -56 30 -95 30
-37 0 -67 9 -115 35 -36 18 -85 44 -110 56 -25 12 -52 26 -60 30 -8 5 -22 12
-30 15 -46 19 -140 69 -169 90 -20 14 -48 24 -68 24 -35 0 -140 50 -181 88
-14 12 -46 32 -71 45 -25 12 -48 24 -51 27 -3 3 -26 15 -51 28 -26 12 -54 34
-63 47 -12 19 -26 25 -54 26 -25 0 -56 13 -97 40 -33 21 -70 43 -82 48 -12 6
-24 19 -28 30 -3 12 -12 21 -20 21 -7 0 -20 6 -27 13 -7 7 -20 16 -28 20 -18
9 -39 22 -97 60 -24 15 -47 27 -53 27 -5 0 -10 5 -10 10 0 6 -7 10 -14 10 -15
0 -102 54 -121 74 -5 6 -27 20 -47 31 -21 11 -38 22 -38 25 0 3 -18 16 -40 30
-22 14 -43 32 -46 42 -6 20 -67 58 -93 58 -25 0 -151 93 -165 122 -6 13 -34
37 -60 55 -27 18 -52 36 -55 41 -13 18 -57 42 -78 42 -25 0 -63 28 -63 47 0 6
-17 24 -37 39 -21 16 -42 33 -48 38 -29 31 -159 128 -173 130 -9 2 -22 13 -28
25 -6 12 -25 32 -43 45 -17 13 -58 49 -91 79 -33 31 -71 64 -86 73 -14 10 -30
28 -34 40 -5 12 -18 24 -29 26 -27 4 -231 206 -231 229 0 10 -11 19 -27 23
-34 8 -221 198 -225 229 -2 14 -11 23 -25 25 -18 3 -105 90 -192 191 -41 49
-93 111 -136 166 -27 33 -55 68 -64 77 -29 31 -41 47 -41 55 -1 15 -36 50 -57
57 -12 4 -23 18 -26 36 -6 28 -79 144 -98 156 -6 3 -14 17 -19 30 -5 13 -21
29 -35 34 -14 5 -25 14 -25 18 0 5 -18 36 -40 68 -24 36 -40 71 -40 89 0 21
-13 44 -40 75 -22 25 -43 54 -46 65 -4 11 -10 22 -14 25 -7 5 -13 16 -64 114
-15 31 -32 56 -37 56 -4 0 -26 37 -48 81 -30 59 -41 94 -41 126 0 32 -7 53
-25 77 -14 18 -25 42 -25 53 0 11 -7 26 -15 33 -8 7 -15 25 -15 39 0 15 -7 36
-15 47 -8 10 -15 31 -15 45 0 15 -7 32 -15 39 -8 7 -15 22 -15 34 -1 11 -7 32
-15 46 -8 14 -14 41 -15 61 0 19 -5 40 -10 45 -31 31 -58 395 -42 567 16 169
40 269 70 299 7 7 12 22 12 34 0 12 7 27 15 34 8 7 15 21 15 31 0 10 4 20 9
24 6 3 20 26 33 51 12 25 32 57 44 70 13 13 25 32 28 42 9 27 81 99 156 157
36 27 67 53 68 57 2 4 9 8 15 8 10 0 150 64 230 105 16 8 37 15 47 15 9 0 23
7 30 15 7 8 27 15 44 15 18 0 41 7 52 15 11 8 42 14 75 15 30 0 60 5 66 11 13
13 615 14 640 1 33 -17 98 -23 128 -12 23 9 26 13 15 20 -8 6 -27 10 -41 10
-14 0 -34 7 -45 15 -14 10 -47 14 -117 15 -55 0 -108 5 -120 12 -28 15 -302
21 -342 7z"/>
<path d="M1875 7841 c-60 -3 -120 -12 -132 -18 -13 -8 -57 -13 -107 -13 -77 0
-142 -16 -166 -40 -3 -3 -32 -16 -65 -29 -33 -14 -62 -27 -65 -30 -3 -3 -22
-15 -43 -25 -37 -19 -63 -40 -99 -82 -26 -29 -41 -40 -68 -47 -52 -13 -184
-202 -195 -281 -4 -25 -11 -49 -16 -52 -5 -3 -9 -17 -9 -32 0 -15 -7 -40 -15
-56 -8 -15 -15 -43 -15 -61 0 -19 -4 -44 -9 -57 -19 -49 -22 -183 -6 -264 8
-43 15 -96 15 -118 0 -21 7 -61 16 -90 9 -28 22 -71 30 -96 8 -25 14 -73 14
-107 0 -60 6 -77 66 -196 8 -16 14 -34 14 -41 0 -16 56 -124 75 -146 8 -9 15
-21 15 -26 0 -12 88 -184 101 -198 5 -6 9 -19 9 -29 0 -20 38 -89 52 -95 4 -2
8 -12 8 -22 0 -9 7 -23 15 -30 8 -7 15 -19 15 -26 0 -8 5 -14 10 -14 6 0 10
-7 10 -15 0 -9 20 -35 45 -59 25 -25 45 -47 45 -51 0 -4 14 -27 30 -52 17 -24
30 -54 30 -67 0 -14 16 -40 38 -63 21 -22 74 -87 117 -144 44 -57 84 -109 90
-115 5 -6 17 -22 25 -34 8 -13 42 -55 75 -95 33 -40 80 -98 105 -128 30 -37
53 -56 68 -57 16 0 22 -6 22 -22 0 -13 12 -36 26 -53 98 -114 149 -166 182
-182 24 -12 40 -28 47 -48 11 -32 178 -205 198 -205 22 0 57 -30 57 -49 0 -27
192 -201 233 -211 12 -3 23 -14 25 -24 4 -19 42 -56 120 -114 26 -20 56 -45
67 -56 18 -19 116 -98 248 -199 65 -50 76 -59 90 -75 53 -60 70 -72 97 -72 17
0 30 -4 30 -8 0 -4 18 -19 40 -32 22 -14 40 -28 40 -32 0 -5 6 -8 14 -8 8 0
28 -17 45 -39 17 -21 56 -56 88 -77 32 -21 64 -45 71 -52 7 -6 26 -12 41 -12
17 0 38 -11 57 -31 26 -28 157 -118 256 -178 21 -13 38 -27 38 -32 0 -5 9 -9
20 -9 19 0 67 -23 125 -61 17 -10 38 -22 48 -25 9 -4 17 -10 17 -15 0 -5 6 -9
13 -9 8 0 24 -11 37 -25 13 -13 39 -33 59 -44 85 -44 106 -56 111 -62 12 -13
92 -49 110 -49 10 0 21 -4 24 -9 3 -5 43 -28 88 -51 46 -23 106 -57 135 -76
28 -19 55 -34 60 -34 5 0 42 -17 84 -39 41 -21 91 -41 111 -45 20 -4 49 -13
64 -20 96 -46 143 -66 157 -66 8 0 28 -10 44 -23 15 -13 57 -34 93 -47 36 -13
69 -27 75 -32 5 -4 18 -8 28 -8 11 0 42 -13 69 -30 39 -25 59 -30 108 -30 33
0 68 -4 78 -9 64 -34 106 -51 127 -51 13 0 26 -4 29 -9 3 -5 23 -12 43 -16 34
-6 105 -26 158 -45 53 -19 134 -40 156 -40 13 0 44 -6 69 -14 75 -24 142 -38
215 -47 39 -4 93 -12 120 -18 66 -13 547 -13 605 0 25 6 66 14 93 18 26 4 53
13 59 19 7 7 26 12 43 12 17 0 36 5 42 11 6 6 26 14 44 19 61 15 108 36 131
57 35 30 173 113 190 113 18 0 58 41 58 59 0 7 25 40 55 73 30 32 55 65 55 73
0 8 14 24 30 37 16 12 30 29 30 39 0 9 6 22 13 28 8 6 21 34 30 63 9 29 26 85
37 123 32 102 39 290 15 391 -10 45 -22 73 -29 71 -6 -2 -14 -39 -18 -83 -3
-43 -12 -104 -18 -134 -18 -79 -70 -201 -100 -231 -49 -50 -110 -128 -110
-141 0 -24 -138 -108 -176 -108 -19 0 -51 -10 -72 -23 -41 -26 -96 -43 -187
-59 -33 -5 -64 -14 -70 -18 -17 -14 -325 -21 -450 -11 -143 12 -318 36 -333
45 -6 3 -32 11 -59 16 -26 5 -77 17 -113 26 -36 8 -99 19 -140 24 -88 9 -164
30 -238 65 -29 14 -61 25 -70 25 -10 0 -27 7 -38 15 -10 8 -29 15 -41 15 -12
0 -24 4 -27 9 -3 4 -19 11 -36 14 -16 4 -47 17 -67 30 -21 14 -53 28 -71 32
-17 3 -32 11 -32 16 0 5 -10 9 -23 9 -13 0 -32 7 -43 15 -10 8 -32 15 -48 15
-16 0 -54 12 -85 26 -92 43 -196 95 -201 102 -8 11 -134 72 -147 72 -7 0 -13
4 -13 9 0 5 -10 13 -22 16 -13 4 -37 18 -55 31 -17 13 -42 24 -55 24 -13 0
-35 6 -50 14 -15 8 -55 29 -90 47 -35 18 -76 45 -93 60 -16 15 -44 35 -62 46
-17 10 -59 35 -93 56 -34 20 -68 37 -76 37 -8 0 -33 13 -56 30 -22 17 -47 30
-53 30 -7 0 -15 6 -18 13 -3 8 -18 20 -34 28 -55 28 -133 81 -133 90 0 17 -83
69 -111 69 -15 0 -29 4 -31 8 -1 4 -21 19 -43 32 -22 13 -51 37 -65 53 -36 42
-196 157 -219 157 -12 0 -53 32 -98 77 -43 42 -98 89 -121 104 -24 15 -62 46
-84 68 -22 23 -45 41 -51 41 -7 0 -32 18 -57 40 -25 23 -73 64 -107 92 -35 28
-63 57 -63 63 0 15 -53 65 -69 65 -17 0 -191 159 -191 174 0 17 -63 76 -81 76
-20 0 -191 176 -207 213 -6 15 -25 35 -41 45 -16 10 -55 47 -87 82 -31 36 -71
80 -89 98 -18 18 -35 46 -38 62 -4 16 -15 31 -26 35 -18 5 -111 106 -111 119
0 4 -24 35 -54 70 -30 35 -57 73 -61 85 -3 12 -10 21 -15 21 -5 0 -11 8 -14
18 -3 9 -16 28 -28 42 -13 14 -41 51 -62 83 -22 32 -49 66 -61 75 -28 23 -75
97 -75 119 0 26 -47 112 -80 148 -15 17 -56 84 -90 150 -34 66 -66 125 -72
132 -26 32 -48 91 -48 130 0 26 -10 64 -26 96 -26 52 -30 62 -59 172 -46 167
-49 189 -49 360 1 155 15 280 34 295 8 6 40 71 40 81 0 5 11 25 25 45 14 20
30 54 35 76 7 25 19 42 34 47 12 5 52 34 88 65 36 31 68 56 72 56 4 0 33 17
64 37 61 40 103 58 167 74 21 6 45 14 51 20 7 6 171 9 427 9 346 0 420 -2 450
-15 20 -8 54 -15 76 -15 22 0 42 -4 46 -9 3 -6 27 -13 53 -16 26 -4 61 -13 79
-21 17 -8 40 -14 51 -14 12 0 24 -4 27 -10 3 -5 14 -10 24 -10 28 0 124 -32
142 -47 8 -7 26 -13 40 -13 13 -1 35 -7 49 -15 14 -8 36 -15 50 -15 14 0 34
-7 44 -15 11 -8 28 -15 39 -15 10 0 25 -7 33 -15 9 -9 34 -15 59 -15 39 0 80
-12 137 -41 10 -5 24 -9 31 -9 7 0 31 -13 53 -30 21 -16 46 -30 55 -30 8 0 42
-13 74 -30 32 -16 63 -30 68 -30 6 0 24 -10 41 -23 48 -36 96 -57 131 -57 17
0 40 -7 51 -15 10 -8 26 -15 34 -15 8 0 27 -11 40 -23 14 -13 38 -30 52 -38
31 -16 151 -80 189 -101 14 -7 34 -22 44 -32 37 -36 95 -66 130 -66 22 0 38
-6 41 -15 4 -8 13 -15 20 -15 16 0 29 -9 73 -50 35 -32 105 -78 136 -90 14 -5
25 -14 25 -20 0 -5 5 -10 10 -10 6 0 28 -11 50 -25 48 -30 50 -30 50 -5 0 12
-10 25 -25 32 -14 6 -25 15 -25 19 0 4 -20 18 -45 30 -25 12 -45 26 -45 30 0
4 -9 10 -19 14 -11 3 -47 30 -81 60 -45 41 -69 55 -91 55 -26 0 -74 22 -89 41
-3 3 -24 16 -47 27 -22 12 -49 32 -60 44 -17 22 -33 32 -113 75 -19 10 -40 24
-48 31 -7 6 -20 12 -28 12 -8 0 -14 4 -14 9 0 5 -11 12 -25 15 -14 4 -25 11
-25 16 0 6 -13 10 -29 10 -17 0 -32 4 -35 9 -3 5 -43 28 -88 51 -46 23 -97 53
-114 66 -47 35 -246 134 -270 134 -11 0 -34 6 -50 14 -16 8 -74 35 -129 61
-55 26 -101 52 -103 56 -2 5 -8 9 -14 9 -6 0 -40 14 -75 30 -34 17 -69 30 -76
30 -6 1 -37 14 -67 30 -34 19 -70 30 -95 30 -44 0 -102 18 -180 54 -63 30
-112 47 -150 53 -30 4 -36 7 -105 37 -19 9 -55 20 -80 25 -25 5 -72 17 -105
26 -33 9 -79 20 -103 25 -23 5 -51 14 -61 20 -11 5 -73 10 -138 10 -70 0 -136
6 -163 14 -53 16 -389 26 -555 17z"/>
<path d="M5475 6491 c-5 -10 36 -43 68 -54 9 -4 17 -11 17 -16 0 -5 19 -18 43
-29 23 -11 52 -30 65 -41 l22 -22 0 21 c0 13 -9 24 -25 30 -14 5 -25 13 -25
17 0 5 -17 19 -38 33 -20 14 -48 35 -62 48 -26 23 -55 29 -65 13z"/>
<path d="M5718 6300 c12 -11 27 -20 33 -20 7 0 3 9 -9 20 -12 11 -27 20 -33
20 -7 0 -3 -9 9 -20z"/>
<path d="M3090 6083 c-30 -8 -71 -18 -90 -23 -105 -27 -158 -43 -183 -56 -16
-8 -38 -14 -48 -14 -45 0 -239 -117 -239 -144 0 -15 -129 -141 -152 -149 -10
-4 -18 -13 -18 -21 0 -8 -4 -16 -9 -18 -5 -1 -23 -29 -40 -61 -39 -72 -41
-131 -8 -179 67 -98 169 -226 196 -246 17 -12 31 -31 31 -41 0 -24 43 -82 115
-153 33 -32 79 -77 103 -100 23 -23 42 -51 42 -61 0 -23 148 -177 170 -177 19
0 110 -90 110 -108 0 -18 134 -152 151 -152 16 -1 109 -84 109 -99 0 -24 33
-41 83 -41 57 0 187 -28 187 -41 0 -4 15 -11 32 -14 18 -4 49 -18 70 -31 21
-13 46 -24 57 -24 41 0 68 -115 42 -177 -16 -39 -11 -77 11 -81 9 -2 40 -26
69 -53 29 -28 70 -60 91 -71 21 -12 38 -25 38 -28 0 -4 27 -26 60 -49 33 -23
60 -48 60 -56 0 -14 21 -25 50 -25 10 0 25 -6 32 -12 7 -7 41 -32 74 -55 57
-39 63 -41 83 -27 11 8 21 26 21 39 0 13 12 39 28 57 36 44 62 92 62 115 0 10
4 23 10 29 38 38 49 402 17 524 -10 36 -20 76 -24 90 -3 14 -18 39 -33 55 -15
17 -31 40 -35 53 -3 12 -11 22 -16 22 -5 0 -9 13 -9 28 0 22 -14 42 -59 85
-35 34 -68 57 -81 57 -25 0 -73 25 -110 56 -14 12 -34 24 -45 27 -11 3 -51 17
-90 31 -38 14 -81 26 -95 26 -14 0 -34 6 -45 14 -11 7 -38 16 -60 19 -91 12
-193 28 -235 38 -25 6 -83 13 -130 16 -47 3 -90 9 -95 14 -6 4 -35 10 -65 12
-30 3 -68 10 -83 16 -16 6 -44 11 -63 11 -62 0 -131 48 -177 122 -25 40 -22
128 4 181 23 44 110 136 150 157 111 60 405 56 472 -7 79 -73 108 -108 126
-149 12 -26 21 -53 21 -60 0 -12 57 -14 353 -12 l352 3 -2 45 c-3 84 -19 195
-31 217 -7 12 -12 38 -12 58 0 64 -55 151 -162 254 -54 52 -98 100 -98 106 0
7 -19 25 -42 41 -24 16 -44 32 -46 37 -2 4 -8 7 -12 7 -5 0 -32 14 -60 30 -28
17 -65 30 -83 30 -18 0 -41 7 -51 15 -11 8 -29 15 -41 15 -12 0 -25 4 -31 9
-5 5 -31 14 -59 21 -27 6 -79 19 -115 27 -91 23 -423 27 -500 6z"/>
<path d="M4663 6043 l-23 -4 0 -1375 0 -1375 26 -24 c15 -14 30 -25 35 -25 7
0 109 -59 119 -70 3 -3 19 -13 37 -23 28 -16 74 -17 550 -17 425 0 528 3 573
15 30 8 69 15 86 15 17 0 48 7 67 15 20 8 48 15 62 15 14 0 43 11 65 25 22 14
45 25 52 25 7 0 55 20 106 45 52 25 97 45 100 45 33 0 242 173 242 200 0 19
26 45 55 54 20 6 145 166 145 186 0 5 4 10 8 10 10 0 42 65 42 86 0 9 6 28 13
42 8 15 21 42 30 60 9 19 17 41 17 50 0 9 7 26 15 36 8 11 15 28 15 37 0 10 6
30 14 46 28 56 43 125 66 303 25 186 1 423 -62 605 -6 17 -13 44 -15 60 -3 17
-11 37 -17 45 -21 28 -46 78 -46 93 0 8 -7 20 -15 27 -9 7 -15 29 -15 51 0 39
-40 119 -60 119 -5 0 -24 21 -41 48 -18 26 -58 76 -89 112 -31 36 -59 73 -63
83 -3 9 -14 17 -23 17 -9 0 -37 18 -62 40 -25 23 -73 59 -106 81 -34 23 -64
47 -69 54 -9 16 -150 85 -175 85 -9 0 -26 7 -36 15 -11 8 -41 15 -67 15 -26 0
-60 5 -76 11 -15 6 -55 15 -88 19 -33 5 -80 13 -105 19 -43 10 -1236 13 -1287
4z m1194 -683 c43 -6 87 -14 98 -19 160 -67 262 -137 315 -215 19 -28 37 -53
41 -56 3 -3 16 -27 30 -55 13 -27 26 -52 29 -55 7 -7 35 -68 50 -110 14 -40
32 -180 33 -255 1 -77 -14 -187 -29 -217 -8 -15 -14 -36 -14 -47 0 -34 -67
-181 -86 -188 -20 -7 -84 -98 -84 -118 0 -20 -20 -38 -112 -100 -55 -38 -74
-45 -114 -45 -30 0 -54 -6 -66 -17 -16 -14 -52 -17 -264 -21 -202 -4 -248 -2
-255 9 -5 8 -9 346 -9 751 0 656 2 738 16 752 13 13 44 16 179 16 90 0 199 -5
242 -10z"/>
<path d="M8155 6040 c-27 -5 -75 -16 -105 -25 -30 -9 -86 -20 -125 -23 -47 -5
-80 -14 -100 -28 -16 -12 -50 -32 -75 -45 -106 -57 -184 -118 -206 -162 -8
-15 -32 -38 -53 -52 -24 -14 -52 -47 -72 -82 -19 -32 -39 -67 -46 -78 -7 -11
-15 -27 -18 -35 -4 -8 -20 -42 -36 -76 l-29 -60 0 -220 c1 -171 4 -224 15
-244 8 -14 14 -35 15 -47 0 -23 6 -37 62 -148 25 -48 42 -71 56 -73 12 -2 24
-11 27 -20 3 -9 38 -51 77 -92 82 -85 195 -150 260 -150 20 0 40 -4 43 -10 3
-5 18 -10 33 -10 15 -1 38 -7 52 -15 14 -8 43 -15 65 -15 22 0 51 -7 65 -15
14 -8 50 -14 80 -15 30 0 71 -5 90 -10 19 -6 82 -13 140 -16 101 -5 225 -28
245 -46 5 -4 18 -8 30 -8 36 -1 133 -70 168 -120 9 -13 17 -39 17 -57 0 -68
-63 -163 -109 -163 -14 0 -35 -7 -45 -15 -11 -8 -27 -15 -37 -15 -9 0 -22 -6
-28 -14 -22 -27 -228 -38 -341 -19 -68 12 -148 38 -165 54 -5 5 -18 9 -30 9
-41 0 -125 120 -125 179 0 21 -4 21 -340 21 l-340 0 -6 -26 c-9 -34 2 -157 15
-183 6 -11 11 -45 11 -77 0 -45 8 -73 36 -132 21 -43 44 -76 54 -79 10 -3 22
-13 26 -23 8 -17 107 -127 181 -202 17 -17 38 -28 56 -28 15 0 44 -10 65 -23
99 -61 171 -91 317 -132 33 -9 74 -21 90 -27 17 -6 127 -13 245 -15 183 -4
221 -3 254 11 21 9 57 16 80 16 22 1 52 7 66 15 14 8 39 14 56 15 17 0 37 7
44 15 7 8 18 15 25 15 8 0 26 6 42 14 89 45 200 96 207 96 16 0 123 90 187
156 47 50 78 93 107 156 23 47 42 93 42 101 0 9 5 19 10 22 6 3 10 18 10 33 0
15 7 40 15 56 22 43 20 318 -4 408 -9 37 -20 71 -24 74 -4 4 -7 15 -7 25 0 10
-6 22 -12 26 -19 12 -48 71 -48 98 0 36 -127 165 -162 165 -15 0 -46 15 -70
33 -63 48 -166 93 -246 107 -24 4 -48 12 -54 18 -7 7 -24 12 -39 12 -14 0 -46
7 -69 15 -23 8 -65 15 -92 15 -27 0 -58 5 -68 10 -11 6 -66 15 -122 20 -144
14 -235 27 -257 40 -11 5 -46 10 -78 10 -57 0 -59 1 -100 49 -24 28 -43 54
-43 59 0 5 -7 23 -15 38 -19 37 -19 85 0 110 8 10 15 26 15 34 0 8 10 26 23
40 12 14 30 37 39 51 18 28 82 63 135 74 18 4 35 11 38 16 4 5 61 9 128 9 160
-1 208 -16 288 -96 32 -32 59 -64 59 -71 0 -7 5 -23 12 -36 6 -12 14 -29 18
-37 6 -13 55 -15 331 -18 250 -2 329 1 341 10 13 11 14 22 6 68 -5 30 -12 92
-15 137 -3 53 -10 86 -19 94 -8 6 -14 20 -14 30 0 31 -109 199 -136 209 -29
11 -94 77 -94 95 0 12 -109 90 -150 109 -8 3 -33 17 -55 31 -22 13 -47 24 -56
25 -9 0 -19 7 -23 15 -4 11 -21 15 -59 15 -30 0 -71 7 -90 15 -20 8 -51 15
-70 15 -18 0 -38 5 -45 12 -15 15 -370 21 -447 8z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
